<template>
    <v-app>
        <div style="padding-top:60px;"></div>
        <v-card
            class="mx-auto"
            elevation="0"
            max-height="500"
            min-height="500"
            style="width:500px"
            outlined
            :loading='cardloader'
        >
            <v-card-title>
                <v-img
                    max-height="65"
                    max-width="55"
                    :src="require('@/assets/z.svg')"
                    style="   display: block;
                              margin-left:5px;
                          "
                    ></v-img>
            </v-card-title>
            <v-card-text>
                    <h2 style="padding-left:10px;">Charges</h2>
                    <v-select 
                        style="padding-left:10px;width:50%;"
                        :items="items"
                        label="Branch Code"
                    ></v-select>
                        <v-tabs
                        v-model="tab"
                        color="basil"
                        grow
                        style="padding:10px;padding-top:-10px;"
                        >
                        <v-tab key="percentage">
                                percentage                            
                        </v-tab>
                        <v-tab key="volume">
                                volume                            
                        </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                        <v-tab-item key="percentage">
                           
                            <v-card-text>
                                <v-row class="pt-4"> 
                                    <v-col cols=2>
                                        <h4>Equity</h4>
                                        <small>Intraday</small>
                                    </v-col>
                                    <v-col cols="8">
                                        <v-slider
                                            v-model="slider"
                                            class="align-center"
                                            :max="85"
                                            :min="10"
                                            hide-details
                                            thumb-label="always"
                                            :tick-labels="ticksLabels"
                                            step="5"
                                            ticks="always"
                                            tick-size="3"
                                        >
                                            <template v-slot:append>
                                            <v-text-field
                                                class="mt-0 pt-0"
                                                hide-details
                                                single-line
                                                type="number"
                                                style="width: 60px"
                                                :value="100-slider"
                                                disabled
                                            ></v-text-field>
                                            </template>
                                        </v-slider>
                                    </v-col>
                                    <v-col>
                                    </v-col>
                                </v-row>
                                <v-row class="pt-4"> 
                                    <v-col cols=2>
                                        <h4>Equity</h4>
                                        <small>Intraday</small>
                                    </v-col>
                                    <v-col cols="8">
                                        <v-slider
                                            v-model="slider"
                                            class="align-center"
                                            :max="85"
                                            :min="10"
                                            hide-details
                                            thumb-label="always"
                                            :tick-labels="ticksLabels"
                                            step="5"
                                            ticks="always"
                                            tick-size="3"
                                        >
                                            <template v-slot:append>
                                            <v-text-field
                                                class="mt-0 pt-0"
                                                hide-details
                                                single-line
                                                type="number"
                                                style="width: 60px"
                                                :value="100-slider"
                                                disabled
                                            ></v-text-field>
                                            </template>
                                        </v-slider>
                                    </v-col>
                                    <v-col>
                                    </v-col>
                                </v-row>
                                <v-row class="pt-4"> 
                                    <v-col cols=2>
                                        <h4>Equity</h4>
                                        <small>Intraday</small>
                                    </v-col>
                                    <v-col cols="8">
                                        <v-slider
                                            v-model="slider"
                                            class="align-center"
                                            :max="85"
                                            :min="10"
                                            hide-details
                                            thumb-label="always"
                                            :tick-labels="ticksLabels"
                                            step="5"
                                            ticks="always"
                                            tick-size="3"
                                        >
                                            <template v-slot:append>
                                            <v-text-field
                                                class="mt-0 pt-0"
                                                hide-details
                                                single-line
                                                type="number"
                                                style="width: 60px"
                                                :value="100-slider"
                                                disabled
                                            ></v-text-field>
                                            </template>
                                        </v-slider>
                                    </v-col>
                                    <v-col>
                                    </v-col>
                                </v-row>
                                <v-row class="pt-4"> 
                                    <v-col cols=2>
                                        <h4>Equity</h4>
                                        <small>Intraday</small>
                                    </v-col>
                                    <v-col cols="8">
                                        <v-slider
                                            v-model="slider"
                                            class="align-center"
                                            :max="85"
                                            :min="10"
                                            hide-details
                                            thumb-label="always"
                                            :tick-labels="ticksLabels"
                                            step="5"
                                            ticks="always"
                                            tick-size="3"
                                        >
                                            <template v-slot:append>
                                            <v-text-field
                                                class="mt-0 pt-0"
                                                hide-details
                                                single-line
                                                type="number"
                                                style="width: 60px"
                                                :value="100-slider"
                                                disabled
                                            ></v-text-field>
                                            </template>
                                        </v-slider>
                                    </v-col>
                                    <v-col>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                       
                        </v-tab-item>
                        <v-tab-item key="volume">
                           
                            <v-card-text>volume</v-card-text>
                       
                        </v-tab-item>
                        </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-app>
</template>
<script>
  export default {
    data: () => ({
      items: ['TNC','DEL'],
      tab:null
    }),
  }
</script>