<template>
  <div id="app">


    
    <router-view/>
  
  </div>
  
</template>
<script>
export default {
    name: 'App',    
    metaInfo() {
        return { 
            title: "RRA Registration – Zebu",
            meta: [
                { name: 'description', content:  'Complete the online registration with Zebu'},
                { property: 'og:title', content: "RRA Registration – Zebu"},
                { property: 'og:site_name', content: 'Zebu'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    }
}
</script>

